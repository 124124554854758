import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxMacdChartTemplate from '@/templates/crypto/xxx-macd-chart';

export default function EthereumMacdChart({ location }: GatsbyPageProps) {
  return (
    <XxxMacdChartTemplate
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      location={location}
      articleId={ArticleList.ETHEREUM_MACD_CHART}
    ></XxxMacdChartTemplate>
  );
}
